.controls {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background-color: white;
  margin-bottom: 32px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2)
}

.suggestionControls {
  display: flex;
  align-items: center;
}

