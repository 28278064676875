.app {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: min-content minmax(0, 1fr);
  grid-template-areas:
    "header"
    "main";
  background-color: #ECEFF1;
  min-width: 300px;
}

.header {
  grid-area: header;
  background-color: white;
  font-family: 'Gugi', cursive;
}

.headerText {
  display: flex;
  font-size: 20px;
  line-height: 25px;
  flex-grow: 1;
}

.main {
  grid-area: main;
}
