.drawingArea {
  width: 100%;
  height: 100%;
  background-color: white;
  position: relative;
}

.canvas {
  width: 100%;
  height: 100%;
  touch-action: none;
}
